@font-face {
    font-family: "LatoRegular";
    src: url("../../assets/fonts/LatoRegular.woff2") format("woff2");
  }
@font-face {
    font-family: "MarcellusRegular";
    src: url("../../assets/fonts/MarcellusRegular.woff2") format("woff2");
}

.searchByName {
    width: 119px;
    position: relative;
    display: inline-block;
    flex-shrink: 0;
    z-index: 0;
}
.bookATreatment {
    position: relative;
}
.button {
    width: 118px;
    margin: 0 !important;
    position: absolute;
    left: 195px;
    border-radius: 20px;
    background-color: #393d44;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 17px 0px;
    box-sizing: border-box;
    z-index: 0;
    text-align: center;
    font-size: 18px;
    color: #faef9b;
    font-family: MarcellusRegular;
    cursor: pointer;
}
.frameChild {
    width: 4px;
    position: absolute;
    margin: 0 !important;
    top: 778px;
    left: 1373px;
    background-color: rgba(179, 190, 197, 0.45);
    height: 1440px;
    transform:  rotate(90deg);
    transform-origin: 0 0;
    z-index: 2;
}
.searchByNameParent {
    position: absolute;
    margin: 0 !important;
    top: 169px;
    left: 67px;
    border-radius: 20px;
    border: 1px solid #393d44;
    box-sizing: border-box;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 85px 10px 24px;
    gap: 18px;
    text-align: left;
    font-size: 14px;
    color: rgba(57, 61, 68, 0.4);
    font-family: LatoRegular;
}

.name {
    position: relative;
    font-size: 14px;
    line-height: 100%;
    font-family: LatoRegular;
    color: #1e1e1e;
    text-align: left;
}
.chevronDownIcon {
    width: 16px;
    position: relative;
    height: 16px;
    overflow: hidden;
    flex-shrink: 0;
    background: url(../../assets/icons/list-table-page-chevron-down.svg)
    no-repeat center;
}
.nameParent {
    width: 110.8px;
    border-radius: 25px;
    border: 1px solid #000;
    box-sizing: border-box;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 44px;
    gap: 26px;
    cursor: pointer;
    background-color: white;
}
.containsParent {
    position: relative;
    border-radius: 25px;
    border: 1px solid #000;
    box-sizing: border-box;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 24px;
    gap: 18px;
    text-align: left;
    font-size: 14px;
    color: #1e1e1e;
    font-family: LatoRegular;
    cursor: pointer;
    background-color: white;
}
.allFiltersParent {
    position: relative;
    border-radius: 25px;
    border: 1px solid #000;
    box-sizing: border-box;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 24px;
    gap: 21px;
    text-align: left;
    font-size: 14px;
    color: #1e1e1e;
    font-family: LatoRegular;
    cursor: pointer;
    background-color: white;
}
.allTagsParent {
    position: relative;
    border-radius: 25px;
    border: 1px solid #000;
    box-sizing: border-box;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 24px;
    gap: 28px;
    text-align: left;
    font-size: 14px;
    color: #1e1e1e;
    font-family: LatoRegular;
    cursor: pointer;
    background-color: white;
}
.frameParent {
    position: absolute;
    margin: 0 !important;
    top: 169px;
    left: 405px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;
    text-align: left;
    font-size: 14px;
    color: #1e1e1e;
    font-family: LatoRegular;
}

.divTable {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 100%;
    color: #8fa9b9;
    display: inline-block;
    width: 74.4px;
}
.div1Table {
    position: absolute;
    top: 0px;
    left: 113px;
    line-height: 100%;
    display: inline-block;
    width: 106.1px;
}
.lavauxTable {
    position: absolute;
    top: 0px;
    left: 232.93px;
    line-height: 100%;
    display: inline-block;
    width: 110.3px;
}
.div2Table {
    position: absolute;
    top: 0px;
    left: 369.39px;
    line-height: 100%;
    display: inline-block;
    width: 70.3px;
}
.massagesClassiqueDeTable {
    position: absolute;
    top: 0px;
    left: 465.87px;
    line-height: 100%;
    display: inline-block;
    width: 122.7px;
}
.nameRachelCurenetherachelbTable {
    margin: 0;
}
.id1708049NameContainerTable {
    position: absolute;
    top: 0px;
    left: 614.73px;
    line-height: 100%;
    display: inline-block;
    width: 139.2px;
}
.ulrichSchmittTable {
    position: absolute;
    top: 0px;
    left: 780.12px;
    line-height: 100%;
    display: inline-block;
    width: 95.1px;
}
.cheminDorzens39Table {
    position: absolute;
    top: 0px;
    left: 901.42px;
    line-height: 100%;
    display: inline-block;
    width: 93.7px;
}
.customerAppTable {
    position: absolute;
    top: 0px;
    left: 1021.33px;
    line-height: 100%;
    display: inline-block;
    width: 119.9px;
}
.may20241700Table {
    position: absolute;
    top: 0px;
    left: 1167.43px;
    line-height: 100%;
    display: inline-block;
    width: 130.9px;
}
.may20241900Table {
    position: absolute;
    top: 0px;
    left: 1324.56px;
    line-height: 100%;
    display: inline-block;
    width: 161.3px;
}
.div3Table {
    position: absolute;
    top: 0px;
    left: 1512.01px;
    line-height: 100%;
    display: inline-block;
    width: 153px;
}
.parentTable {
    width: 1665px;
    position: relative;
    height: 82.7px;
    top: 252px;
}
.frameChildTable {
    position: absolute;
    top: 45.81px;
    left: 425px;
    background-color: #b3bec5;
    width: 4px;
    height: 456px;
    transform:  rotate(90deg);
    transform-origin: 0 0;
}
.frameParentTable {
    width: 100%;
    position: relative;
    /* height: 662px; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    box-sizing: border-box;
    gap: 17px;
    text-align: left;
    font-size: 13.78px;
    color: #1e1e1e;
    font-family: Lato;
}

.tableContainer {
    width: 100%;
    position: relative;
    /* height: 662px; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    box-sizing: border-box;
    gap: 17px;
    text-align: left;
    font-size: 13.78px;
    color: #1e1e1e;
    font-family: Lato;
    top: 100px;
}

.customTable {
    width: 80%;
    border-collapse: collapse;
    font-family: LatoRegular;
    border-radius: 40px;
    margin-left: 75px;
    margin-top: -60px;
}

html {
    scrollbar-width: none;
}
  
.tableHeader {
    width: 100%;
    position: relative;
    margin: 0 !important;
    box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.06);
    height: 39px;
    border-radius: 40px !important;
}

.taskColor {
    color: #8fa9b9 !important;
}

th {
    white-space: nowrap;
    word-break: keep-all;
    text-align: left !important;
    padding: 16px;
}

.tableCell {
    padding: 16px;
    text-align: left;
    font-size: 14px;
    color: #1e1e1e;
    font-family: LatoRegular;
}


.scrollableTableContainer {
    overflow-x: auto;
    overflow-y: auto;
    white-space: nowrap;
    min-height: 300px;
}

.scrollableTableContainer::after {
    content: "";
    display: block;
    height: 8px;
    background: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.scrollableTableContainer::-webkit-scrollbar {
    height: 8px;
}

.scrollableTableContainer::-webkit-scrollbar-thumb {
    background: #B3BEC573;
    border-radius: 10px;
}

.scrollableTableContainer::-webkit-scrollbar-thumb:hover {
    background: #888;
}
