@font-face {
    font-family: "LatoRegular";
    src: url("../../assets/fonts/LatoRegular.woff2") format("woff2");
  }
  @font-face {
    font-family: "MarcellusRegular";
    src: url("../../assets/fonts/MarcellusRegular.woff2") format("woff2");
  }

body {
margin: 0; line-height: normal;
}

.title {
    width: 298px;
    position: relative;
    font-family: MarcellusRegular;
    display: inline-block;
    z-index: 0;
}
.label {
    position: relative;
    letter-spacing: 0.1px;
    line-height: 20px;
    font-weight: 500;
    z-index: 0;
}
.indicator {
    flex-shrink: 0;
    height: 14px;
    position: absolute;
    right: 0px;
    left: 0px;
    bottom: 0px;
  }
.shape {
    background: #1d1b20;
    height: 3px;
    position: absolute;
    right: 2px;
    left: 2px;
    bottom: 0px;
  }
.tabContents {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 14px 0px;
    position: relative;
    gap: 4px;
}
.stateLayer {
    align-self: stretch;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 16px;
}
.tab1 {
    flex: 1;
    background-color: #f6f6f6;
    height: 48px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    color: #1d1b20;
}
.label1 {
    position: relative;
    letter-spacing: 0.1px;
    line-height: 20px;
    font-weight: 500;
}
.tabContents1 {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 14px 0px;
}
.tab2 {
    flex: 1;
    background-color: #f6f6f6;
    height: 48px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.tabGroup {
    align-self: stretch;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.divider1 {
    align-self: stretch;
    position: relative;
    border-top: 1px solid #e6e0e9;
    box-sizing: border-box;
    height: 1px;
}
.divider {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.tabs {
    width: 298px;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.vectorIcon {
    width: 100%;
    position: relative;
    height: 15px;
    object-fit: contain;
}
.tabsParent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    z-index: 1;
    text-align: center;
    font-size: 14px;
    color: #b3bec5;
}
.statusSuccessful {
    margin: 0;
}
.antiStressLp {
    font-family: LatoRegular;
}
.title1 {
    width: 308px;
    position: relative;
    font-size: 16px;
    line-height: 36px;
    display: inline-block;
    z-index: 2;
}
.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 16.54px;
    background-color: #8fa9b9;
    width: 72.9px;
    height: 75.2px;
}
.enRoute {
    position: absolute;
    top: 21.48px;
    left: 0px;
    line-height: 24.42px;
    display: inline-block;
    width: 48.8px;
    height: 24.4px;
}
.vectorIcon1 {
    position: absolute;
    height: 43.89%;
    width: 62.5%;
    top: 0%;
    right: 19.43%;
    bottom: 56.11%;
    left: 18.07%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    background: url(../../assets/icons/dashboard-home-page-car.svg);
}
.enRouteParent {
    position: absolute;
    top: 16.88px;
    left: 12.83px;
    width: 48.8px;
    height: 45.8px;
}
.rectangleParent {
    width: 72.9px;
    position: relative;
    height: 75.2px;
    z-index: 0;
    color: #fff;
}
.groupItem {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 16.54px;
    background-color: rgba(179, 190, 197, 0.12);
    width: 87.4px;
    height: 75.2px;
}
.prparation {
    position: absolute;
    top: 21.48px;
    left: 0px;
    line-height: 24.42px;
    display: inline-block;
    width: 66.1px;
    height: 24.4px;
}
.vectorIcon2 {
    position: absolute;
    height: 46.94%;
    width: 50.98%;
    top: 0%;
    right: 24.76%;
    bottom: 53.06%;
    left: 24.25%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    background: url(../../assets/icons/dashboard-home-page-prepa.svg);
}
.prparationParent {
    position: absolute;
    top: 16.88px;
    left: 10.42px;
    width: 66.1px;
    height: 45.8px;
}
.rectangleGroup {
    width: 87.4px;
    position: relative;
    height: 75.2px;
    z-index: 1;
}
.groupInner {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 16.54px;
    background-color: rgba(179, 190, 197, 0.12);
    width: 65.7px;
    height: 75.2px;
}
.massage {
    position: absolute;
    top: 27.62px;
    left: 0px;
    line-height: 24.42px;
    display: inline-block;
    width: 49.9px;
    height: 24.4px;
}
.vectorIcon3 {
    position: absolute;
    height: 53.08%;
    width: 57.92%;
    top: 0%;
    right: 21.2%;
    bottom: 46.92%;
    left: 20.88%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    background: url(../../assets/icons/dashboard-home-page-massage.svg);
}
.massageParent {
    position: absolute;
    top: 10.74px;
    left: 7.21px;
    width: 49.9px;
    height: 52px;
}
.rectangleContainer {
    width: 65.7px;
    position: relative;
    height: 75.2px;
    z-index: 2;
}
.termin {
    position: absolute;
    top: 20.72px;
    left: 0px;
    line-height: 24.42px;
    display: inline-block;
    width: 45.8px;
    height: 24.4px;
}
.vectorIcon4 {
    position: absolute;
    height: 40.8%;
    width: 48.91%;
    top: 0%;
    right: 24.84%;
    bottom: 59.2%;
    left: 26.25%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    background: url(../../assets/icons/dashboard-home-page-done.svg);
}
.terminParent {
    position: absolute;
    top: 17.65px;
    left: 10.42px;
    width: 45.8px;
    height: 45.1px;
}
.groupDiv {
    width: 65.7px;
    position: relative;
    height: 75.2px;
    z-index: 3;
}
.frameChild {
    width: 9px;
    height: 0px;
    position: absolute;
left: 72px;
stroke-width: 1px;
stroke: #8FA9B9;

bottom: 37.593px;
    background: url(../../assets/icons/dashboard-home-page-related.svg);
}
.groupParent {
    width: 308px;
    margin: 0 !important;
    position: absolute;
    top: 310px;
    left: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 7.1px;
    z-index: 3;
    font-size: 12.6px;
    color: #8fa9b9;
}
.listItemWithImage {
    width: 100%;
    position: absolute;
    margin: 0 !important;
    top: 0px;
    left: 338px;
    box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    background-color: #fff;
    height: 753px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 13px 16px 40px;
    box-sizing: border-box;
    gap: 23px;
    text-align: left;
    font-size: 22px;
    color: #393d44;
    font-family: LatoRegular;
}

.activeTab {
    position: relative;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 20px;
    font-weight: 500;
    font-family: LatoRegular;
    color: #1d1b20;
    text-align: center;
}

.generalInfo {
    width: 308px;
    position: relative;
    font-size: 20px;
    line-height: 20px;
    font-family: MarcellusRegular;
    color: #393d44;
    text-align: left;
    display: inline-block;
    height: 25px;
}

.nameClient {
    margin: 0;
}

.titleClient {
    width: 61px;
    position: relative;
    line-height: 36px;
    display: inline-block;
    height: 162px;
    flex-shrink: 0;
}

.spanClient {
    line-height: 36px;
}

.romanelSurLausanneClient {
    line-height: 20px;
}

.blankLine1Client {
    margin: 0;
    line-height: 36px;
}

.title1Client {
    width: 223px;
    position: relative;
    display: inline-block;
    height: 162px;
    flex-shrink: 0;
}

.titleParentClient {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 23px;
    text-align: left;
    font-size: 16px;
    color: #393d44;
    font-family: LatoRegular;
}

.mayHistory {
    margin: 0;
}
.pHistory {
    margin: 0;
    font-weight: 500;
}
.mayContainerHistory {
    position: relative;
}
.ratingHistory {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 57px;
    height: 23.5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0px;
    box-sizing: border-box;
}
.ratingWrapperHistory {
    width: 57px;
    position: relative;
    height: 23.5px;
}
.groupWrapperHistory {
    width: 87.4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 19px 0px;
    box-sizing: border-box;
}
.groupChildHistory {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 16.54px;
    background-color: rgba(179, 190, 197, 0.12);
    width: 210px;
    height: 68px;
}
.locationOnMapHistory {
    position: absolute;
    top: 38px;
    left: 18px;
}
.byRachelHistory {
    width: 60px;
    position: relative;
    font-weight: 500;
    display: inline-block;
    flex-shrink: 0;
}
.rating1History {
    position: absolute;
    top: 18px;
    left: 110px;
    width: 85px;
    height: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0px;
    box-sizing: border-box;
}
.completedHistory {
    position: relative;
    font-weight: 500;
}
.rating2History {
    position: absolute;
    top: 16px;
    left: 16.63px;
    border-radius: 13px;
    background-color: #45c343;
    width: 88px;
    height: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 0px;
    box-sizing: border-box;
    font-size: 11px;
    color: #fff;
}
.rectangleParentHistory {
    width: 210px;
    position: relative;
    height: 68px;
}
.groupContainerHistory {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 12px;
}
.frameGroupHistory {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    z-index: 0;
}
.rating4History {
    position: absolute;
    top: 18px;
    left: 96.63px;
    width: 98px;
    height: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0px;
    box-sizing: border-box;
}
.rating5History {
    position: absolute;
    top: 16px;
    left: 16.63px;
    border-radius: 13px;
    background-color: #4d5ec4;
    width: 75px;
    height: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 0px;
    box-sizing: border-box;
    font-size: 11px;
    color: #fff;
}
.frameContainerHistory {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    z-index: 1;
}
.rating7History {
    position: absolute;
    top: 18px;
    left: 91.63px;
    width: 103px;
    height: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0px;
    box-sizing: border-box;
}
.rating8History {
    position: absolute;
    top: 16px;
    left: 16.63px;
    border-radius: 13px;
    background-color: #1c9dff;
    width: 70px;
    height: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 0px;
    box-sizing: border-box;
    font-size: 11px;
    color: #fff;
}
.frameParent1History {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    z-index: 2;
}
.rating10History {
    position: absolute;
    top: 18px;
    left: 102.63px;
    width: 92px;
    height: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0px;
    box-sizing: border-box;
}
.rating11History {
    position: absolute;
    top: 16px;
    left: 16.63px;
    border-radius: 13px;
    background-color: #ff8a00;
    width: 81px;
    height: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 0px;
    box-sizing: border-box;
    font-size: 11px;
    color: #fff;
}
.frameParent2History {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    z-index: 3;
}
.harris31356History {
    position: absolute;
    top: 38px;
    left: 18px;
    font-weight: 500;
}
.byApiApiHistory {
    width: 107px;
    position: relative;
    font-weight: 500;
    white-space: pre-wrap;
    display: inline-block;
    flex-shrink: 0;
}
.rating13History {
    position: absolute;
    top: 18px;
    left: 92.63px;
    width: 85px;
    height: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0px;
    box-sizing: border-box;
}
.rating14History {
    position: absolute;
    top: 16px;
    left: 16.63px;
    border-radius: 13px;
    background-color: #8fa9b9;
    width: 70px;
    height: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 0px;
    box-sizing: border-box;
    font-size: 11px;
    color: #fff;
}
.frameParent3History {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    z-index: 4;
}
.frameChildHistory {
    width: 100%;
    position: absolute;
    margin: 0 !important;
    top: 30px;
    left: 66px;
    max-width: 100%;
    overflow: hidden;
    height: 313px;
    flex-shrink: 0;
    object-fit: contain;
}
.frameParentHistory {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    text-align: left;
    font-size: 11px;
    color: #393d44;
    font-family: LatoRegular;
}

.ellipseDivGrey {
    width: 100%;
    position: relative;
    backdrop-filter: blur(4px);
    border-radius: 50%;
    background-color: rgba(143, 169, 185, 0.5);
    border: 1px solid #bac7cf;
    box-sizing: border-box;
    height: 10px;
}

.ellipseDivGreen {
    width: 100%;
    position: relative;
    border-radius: 50%;
    background-color: #45c343;
    border: 1px solid #45c343;
    box-sizing: border-box;
    height: 10px;
}

.vectorIconGreen {
    width: 100%;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 223px;
    background: url(../../assets/images/dashboard-home-page-vector-23.svg) lightgray 50% / cover no-repeat;
    
}
  
